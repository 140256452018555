const getDefaultState = () => {
  return {
    permission: [],
    isLoggedIn: false
  }
}
// initial state
const state = getDefaultState()

const getters = {
  permission(state) {
    return state.permission
  },
  isLoggedIn(state) {
    return state.isLoggedIn
  }
}

const mutations = {
  /**
   * Set permission data
   * @param {Object} state
   * @param {Object} permission
   */
  SET_PERMISSION(state, permission) {
    state.permission = permission
  },
  SET_ISLOGGEDIN(state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn
  }
}

const actions = {
  setPermission({ commit }, permission) {
    commit('SET_PERMISSION', permission)
  },
  setIsLoggedIn({ commit }, isLoggedIn) {
    commit('SET_ISLOGGEDIN', isLoggedIn)
  }
}

export const auth = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
