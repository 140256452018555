import Vue from 'vue'
import { ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

require('@core/assets/fonts/feather/iconfont.css')

import router from './router'
import store from './store'
import App from './App.vue'
import PortalVue from 'portal-vue'
import './global-components'

// Enable Event Bus
window.Bus = {}
window.Bus.instance = new Vue({})
Vue.config.performance = true
// BSV Plugin Registration

Vue.use(ModalPlugin)
Vue.use(PortalVue)
// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
