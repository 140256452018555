import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

//////// VALIDATION PROVIDER ////////
import { ValidationProvider } from 'vee-validate'
Vue.component('ValidationProvider', ValidationProvider)

import './libs/toastification'
import './libs/sweet-alerts'
import './libs/acl'

import Ripple from 'vue-ripple-directive'

import PortalVue from 'portal-vue'

Vue.use(PortalVue)

import Flicking from '@egjs/vue-flicking'

Vue.use(Flicking)

import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

// Or, if you have to support IE9
import '@egjs/vue-flicking/dist/flicking-inline.css'

// new directive with Ripple
Vue.directive('ripple', Ripple)

/////// BOOTSTRAP GLOBAL COMPONENTS ///////

Vue.component(FeatherIcon.name, FeatherIcon)
