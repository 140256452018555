export default [
  {
    path: '/',
    name: 'dashboard',
    component: () =>
      import(
        /* webpackChunkName: "js/chunks/shared/Dashboard" */ '@/pages/Dashboard.vue'
      ),
    meta: {
      resource: 'shared',

      requiresAuth: true
    }
  },
  {
    path: '/maps',
    name: 'maps',
    component: () =>
      import(
        /* webpackChunkName: "js/chunks/shared/maps" */ '@/pages/Maps.vue'
      ),
    meta: {
      resource: 'operator',
      admin: true
    }
  },
  {
    path: '/cameras',
    name: 'cameras',
    component: () =>
      import(
        /* webpackChunkName: "js/chunks/shared/cameras" */ '@/pages/Cameras.vue'
      ),
    meta: {
      resource: 'operator',
      admin: true,
      pageTitle: 'Cameras',
      breadcrumb: [
        {
          text: 'Cameras',
          active: true
        }
      ]
    }
  },
  {
    path: '/remotedesktops',
    name: 'remotedesktops',
    component: () =>
      import(
        /* webpackChunkName: "js/chunks/shared/remotedesktops/" */ '@/pages/RemoteDesktop.vue'
      ),
    meta: {
      resource: 'operator',
      admin: true,
      pageTitle: 'Remote Desktops',
      breadcrumb: [
        {
          text: 'Remote Desktops',
          active: true
        }
      ]
    }
  },
  {
    path: '/sitemanager',
    name: 'sitemanager',
    component: () =>
      import(
        /* webpackChunkName: "js/chunks/admin/siteManager" */ '@/pages/Admin/SiteManager.vue'
      ),
    meta: {
      resource: 'admin',
      pageTitle: 'Site Manager',
      breadcrumb: [
        {
          text: 'Site Manager',
          active: true
        }
      ]
    }
  },
  {
    path: '/regionmanager',
    name: 'regionmanager',
    component: () =>
      import(
        /* webpackChunkName: "js/chunks/shared/regionManager" */ '@/pages/Admin/RegionManager.vue'
      ),
    meta: {
      resource: 'admin',
      pageTitle: 'Region Manager',
      breadcrumb: [
        {
          text: 'Region Manager',
          active: true
        }
      ]
    }
  },
  {
    path: '/regioneditor/:id',
    name: 'regioneditor',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "js/chunks/admin/regionEditorwID" */ '@/pages/Admin/RegionEditor.vue'
      ),
    meta: {
      resource: 'admin',
      pageTitle: 'Region Editor',
      breadcrumb: [
        {
          text: 'Region Editor',
          active: true
        }
      ]
    }
  },
  {
    path: '/siteeditor/:id',
    name: 'siteeditor',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "js/chunks/admin/siteEditorwID" */ '@/pages/Admin/SiteEditor.vue'
      ),
    meta: {
      resource: 'admin',
      pageTitle: 'Site Editor',
      breadcrumb: [
        {
          text: 'Site Editor',
          active: true
        }
      ]
    }
  },
  {
    path: '/globalQuicklinkManager',
    name: 'qlmanager',
    component: () =>
      import(
        /* webpackChunkName: "js/chunks/admin/quickLinkManager" */ '@/pages/Admin/GlobalQuickLinks.vue'
      ),
    meta: {
      resource: 'admin',
      pageTitle: 'Quick Links (Global)',
      breadcrumb: [
        {
          text: 'Quick Links (Global)',
          active: true
        }
      ]
    }
  },
  {
    path: '/contractormanager',
    name: 'contractormanager',
    component: () =>
      import(
        /* webpackChunkName: "js/chunks/admin/contractorManager" */ '@/pages/Admin/ContractorManager.vue'
      ),
    meta: {
      resource: 'admin',
      pageTitle: 'Contractor Manager',
      breadcrumb: [
        {
          text: 'Contractor Manager',
          active: true
        }
      ]
    }
  },
  {
    path: '/contractoreditor/:id',
    name: 'contractoreditor',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "js/chunks/admin/contractorEditorwID" */ '@/pages/Admin/ContractorEditor.vue'
      ),
    meta: {
      resource: 'admin',
      pageTitle: 'Contractor Editor',
      breadcrumb: [
        {
          text: 'Contractor Editor',
          active: true
        }
      ]
    }
  }
]
