export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Login.vue'),
    meta: {
      guestAccess: true,
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/pages/Error/Error404.vue'),
    meta: {
      guestAccess: true,
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/error-401',
    name: 'error-401',
    component: () => import('@/pages/Error/Error401.vue'),
    meta: {
      guestAccess: true,
      layout: 'full'
    }
  }
]
