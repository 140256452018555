import store from '@/store'
import axios from 'axios'
import router from '.'
import { Ability, AbilityBuilder } from '@casl/ability'
import ability from '@/libs/acl/ability'

// Return status of logged in user
export const loggedIn = () => {
  return store.state.auth.isLoggedIn
}

// If user is not logged in, call backend to get user data
export function retrieveUserAccess(to, from, next) {
  return new Promise((resolve, reject) => {
    axios
      .get('https://dev-cloudflaredapi.opstunnel.com/api/whoami', {
        withCredentials: true
      })
      .then((response) => {
        // store dispatch response to auth/permission
        store.dispatch('auth/setPermission', response.data.roles)
        store.dispatch('auth/setIsLoggedIn', true)
        // run userPermissions  function with the response data
        ability.update(userPermissions(response.data.roles)) // create new ability with userPermissions
        resolve(response, next)
      })
      .catch((error) => {
        router.push({ name: 'error-401' })
        reject(error)
      })
  })
}

export async function checkAccessMiddleware(to, from, next) {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.auth.isLoggedIn === false) {
      console.log('You must login to view this page')
      next({
        name: 'login'
      })
    }
  }
  if (to.matched.some((record) => record.meta.admin)) {
    if (store.state.auth.includes('admin')) {
      next()
    } else {
    }
  }
  next()
}

export function userPermissions(response) {
  let userData = response
  console.log(userData)
  // if userdata contains admin in array
  try {
    if (userData.includes('admin')) {
      return [
        {
          action: 'manage',
          subject: 'all'
        },
        { action: 'read', subject: 'shared' }
      ]
    }
    if (userData.includes('operator')) {
      return [
        {
          action: 'read',
          subject: 'operator'
        },
        { action: 'read', subject: 'shared' }
      ]
    }
    if (userData.includes('contractor')) {
      return [
        {
          action: 'read',
          subject: 'contractor'
        },
        { action: 'read', subject: 'shared' }
      ]
    }
    if (!userData) {
      return [
        {
          action: '',
          subject: ''
        }
      ]
    }
  } catch (error) {
    alert('exception: ' + e)
  }
}

export const canNavigate = (to) =>
  to.matched.some((route) =>
    ability.can(route.meta.action || 'read', route.meta.resource)
  )
