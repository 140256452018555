import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  retrieveUserAccess,
  checkAccessMiddleware,
  isUserLoggedIn,
  loggedIn,
  canNavigate,
  checkAuth
} from './middlewares'
import others from './routes/others'
import ability from '@/libs/acl/ability'
import pageRoutes from './routes/pageRoutes'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...pageRoutes,
    ...others,
    {
      path: '*',
      redirect: { name: 'error-404' }
    }
  ]
})

// Check if user is logged in, if not perform request and dispatch to session
router.beforeEach((to, from, next) => {
  const userLoggedIn = loggedIn()
  if (!userLoggedIn) {
    retrieveUserAccess()
    next()
  }
  // if user is logged in and the route has guestAccess = true, then proceed
  if (to.meta.guestAccess) {
    next()
  }

  if (
    userLoggedIn &&
    to.matched.some((record) => record.meta.resource.includes('admin'))
  ) {
    if (ability.can('manage')) {
      next()
    } else {
      next({ name: 'error-401' })
    }
  } else {
    next()
  }
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
