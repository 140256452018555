import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import { auth } from './modules/auth'
// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

const ls = new SecureLS({
  encodingType: 'aes',
  encryptionSecret: 'secret'
})

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth
  },
  plugins: [
    createPersistedState({
      key: 'metadata',
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      }
    })
  ],
  strict: process.env.DEV
})
