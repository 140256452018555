import { Ability } from '@casl/ability'
import { initialAbility } from './config'
import store from '@/store'
//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

export default new Ability(userPermissions() || initialAbility)

function userPermissions() {
  let userData = store.state.auth.permission

  // if userdata contains admin in array
  try {
    if (userData.includes('admin')) {
      return [
        {
          action: 'manage',
          subject: 'all'
        }
      ]
    }
    if (userData.includes('operator')) {
      return [
        {
          action: 'read',
          subject: 'operator'
        },
        { action: 'read', subject: 'shared' }
      ]
    }
    if (userData.includes('contractor')) {
      return [
        {
          action: 'read',
          subject: 'contractor'
        },
        { action: 'read', subject: 'shared' }
      ]
    }
    if (userData === '') {
      return [
        {
          action: '',
          subject: ''
        }
      ]
    }
  } catch (error) {
    alert('exception: ' + e)
  }
}

let user = userPermissions()
